@import "variables";
@import "mixins";

.closemenu {
    color: $primaryColor;
    position: relative;
    right: 0;
    left: 205px;
    z-index: 999;
    font-size: $fz18 !important;
    top: 0;
    transition: all 0.35s ease;
    transform: translateX(0);
    cursor: pointer;
}

.all-page-header-section {
    position: absolute;
    left: 20%;
    top: 18%;
}

.topbar-nav .toggle-shift-left {
    position: relative;
    left: 75px;
    z-index: 9;
}

.topbar-nav .dropdown-toggle {
    background-color: transparent;
    border: none;
    position: relative;
    top: 0;
    right: 0;
    color: $whiteColor;
    display: inline-flex;
    // width: 235px;
    box-shadow: none;
    margin-right: 30px;
}

.topbar-nav .dropdown-toggle:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.credit-rqst-btn {
    padding: 1px 5px;
    color: $whiteColor;
    border-radius: 5px;
    font-size: 11px;
    background-color: $pendStatusBackground;
    color: $pendStatusColor;
    border: 1px solid $pendStatusBackground;
    cursor: pointer;
}

.total-credit {
    color: $primaryColor;
}

.sb-remaining-credits {
    color: $secondaryColor;
}

.custom-sb-drop {
    .dropdown-item:hover {
        background-color: $whiteColor;
    }

    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(0px, 64px);
}

.dropdown-toggle::after {
    content: "";
    width: 30px;
    height: 30px;
    background: url("../../public/images/Topbar/down-caret.png");
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    font-size: $fz30;
    position: absolute;
    top: 15px;
    right: -20px;
    border-top: 0 solid $nav_back_Graycolor;
    border-right: 0 solid transparent;
    border-bottom: 0;
    border-left: 0 solid transparent;
}

.header-item {
    .dropdown-menu.show {
        display: block;
        transform: translate(-30px, 65px) !important;
        // margin-left: 35px !important;
        // margin-top: 25px !important;
        // box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 20%) !important;
        // border-top-style: hidden;
    }
}

.logout-text {
    color: $danderColor;
    font-weight: 600;
}

.dropdown-item:hover {
    color: $whiteColor;
    // background-color: $primaryColor;
}

.dropdown-item:active {
    background-color: $lightGrayishBlue !important;
}

.icon-divider {
    border-right: 1px solid $primarySemiLight;
    height: 20px;
}

.dropdown-item:focus {
    color: $whiteColor;
    background-color: $primaryColor;
}


.topbar {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    // height: 52px;
    height: 63px;
    background: $whiteColor;
    z-index: 9;
    box-shadow: $topBarShadow;
    // box-shadow: 2px 5px 11px #f1f1f1;
}

.navbar-custom {
    position: relative;
    left: 0;
    top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 63px;
}

.logo-img {
    border-radius: 50%;
    // border: 3px solid #21ab79;
}

.list-unstyled {
    padding: 5px;
    height: 63px;
    display: flex;
    align-items: center;
}

.sb-user-name {
    text-align: left;
    font-weight: 700;
    color: $primaryText_Color;
    padding-top: 5px;
    font-size: 15px;
}

.sb-company-name {
    font-weight: 500;
}

.topbar-nav {
    .button-menu-mobile {
        background-color: transparent;
        box-shadow: none;
        border: none;
        outline: none;

        &:hover,
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}

.logout-modal-div {
    top: 35%;

    .modal-content {
        padding: 25px;

        .sb-logout-btn,
        .log-out-div {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .log-out-div {
            text-align: center;
            flex-direction: column;

            p {
                margin-top: 10px;
            }
        }
    }
}

.top-bar-popup {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 150px;

    .dashboard-pop-img {
        margin-left: 15%;
        height: 100%;
    }

    .carousel-div {
        position: absolute;
        top: 0px !important;
        width: 100% !important;

        .first-popup-div {
            min-height: 50px;
            min-width: 100px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            box-shadow: $card_shadow_hover;
            background: $lightGrayishCyan;
            padding: 5px 10px;

            .first-popup-div-text {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                p {
                    font-size: 12px;
                    text-align: center;
                }
            }
        }

        .first-popup-div.active {
            opacity: 0;
            animation: 4.5s anim-popoutin ease;

            &:hover {
                animation-play-state: paused;
            }
        }

        @keyframes anim-popoutin {
            0% {
                transform: scale(0);
                opacity: 0;
                //   text-shadow: 0 0 0 rgba(0, 0, 0, 0);
                text-shadow: $lightGrayishBlue;
            }

            30% {
                transform: scale(1);
                opacity: 1;
                // text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
            }

            50% {
                transform: scale(1);
                opacity: 1;
            }

            70% {
                transform: scale(1);
                opacity: 1;
            }

            100% {
                transform: scale(0);
                opacity: 0;
            }
        }

        .carousel-text2 {
            background-color: $vividYellow;
            transition: opacity 3s ease-in-out;
            transition-delay: 3s;
        }

        .carousel-text3 {
            background-color: $softCyan;
            transition: opacity 3s ease-in-out;
            transition-delay: 3s;
        }

        //   .carousel-text.active {
        //     opacity: 1;
        //   }
    }

}


.your-days-left {
    position: relative;
    top: 0px;
    right: 100px;
    color: $whiteColor !important;
    animation-name: moving;
    animation-duration: 5s;
    animation-iteration-count: 5;
    animation-direction: alternate-reverse;
    opacity: 1;

    .go-to-accounts {
        text-decoration: none;
        color: $whiteColor !important;
    }

    .days-left-in-word {
        font-size: $fz14;
        color: $dangerColor;

        b {
            font-size: $fz15;
        }

        i {
            padding-right: 5px;
        }
    }

    .days-left-in-number {
        font-size: $fz14;
        color: $secondaryColor;

        b {
            font-size: $fz15;
        }

        i {
            padding-right: 5px;
        }

        &.red {
            color: $dangerColor;
        }
    }
}

@keyframes moving {
    0% {
        top: 0px;
        right: 100px;
    }

    100% {
        top: 0px;
        right: 250px;
    }
}


// toggle switch
:root {
    --hue: 223;
    --bg: hsl(var(--hue), 10%, 90%);
    --fg: hsl(223, 10%, 10%);
    --primary: hsl(var(--hue), 90%, 50%);
    --trans-dur: 0.3s;
    --trans-timing: cubic-bezier(0.76, 0.05, 0.24, 0.95);
    --trans-timing-in: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    --trans-timing-out: cubic-bezier(0.05, 0.76, 0.06, 0.86);
    // font-size: calc(40px + (80 - 40) * (100vw - 320px) / (2560 - 320));
}

.switch {
    margin: auto;
    position: relative;
}

.switch__icon,
.switch__input {
    display: block;
}

.switch__icon {
    position: absolute;
    top: 0.375em;
    right: 0.375em;
    width: 0.75em;
    height: 0.75em;
    transition:
        opacity calc(0.3s / 2),
        transform calc(0.3s / 2);
}

.switch__icon polyline {
    transition: stroke-dashoffset calc(0.3s / 2);
}

.switch__icon--light,
.switch__icon--light polyline {
    transition-delay: calc(0.3s / 2);
    transition-timing-function: var(--trans-timing-out);
}

.switch__icon--dark {
    opacity: 0;
    transform: translateX(-0.75em) rotate(30deg) scale(0.75);
    transition-timing-function: var(--trans-timing-in);
}

.switch__input {
    // background-color: hsl(290,90%,40%);
    background-color: $primaryColor;

    border-radius: 0.75em;
    box-shadow:
        0 0 0 0.125em hsla(var(--hue), 90%, 50%, 0),
        0.125em 0.125em 0.25em hsla(var(--hue), 90%, 10%, 0.2);
    outline: transparent;
    position: relative;
    width: 3em;
    height: 1.5em;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    transition:
        background-color 0.3s var(--trans-timing),
        box-shadow 0.15s linear;
}

.switch__input:focus-visible {
    box-shadow:
        0 0 0 0.125em hsl(var(--hue), 90%, 50%),
        0.125em 0.125em 0.25em hsla(var(--hue), 90%, 10%, 0.2);
}

.switch__input:before,
.switch__input:after {
    content: "";
    display: block;
    position: absolute;
}

.switch__input:before {
    background-color: hsl(220, 90%, 40%);
    // background-color: $primaryColor;

    border-radius: inherit;
    mask-image: linear-gradient(120deg, hsl(0, 0%, 0%) 20%, hsla(0, 0%, 0%, 0) 80%);
    -webkit-mask-image: linear-gradient(120deg, hsl(0, 0%, 0%) 20%, hsla(0, 0%, 0%, 0) 80%);
    inset: 0;
    transition: background-color 0.3s var(--trans-timing);
}

.switch__input:after {
    background-color: hsl(0, 0%, 100%);
    border-radius: 50%;
    box-shadow: 0.05em 0.05em 0.05em hsla(var(--hue), 90%, 10%, 0.1);
    top: 0.125em;
    left: 0.125em;
    width: 1.25em;
    height: 1.25em;
    transition:
        background-color 0.3s var(--trans-timing),
        transform 0.3s var(--trans-timing);
    z-index: 1;
}

.switch__input:checked {
    // background-color: hsl(210,90%,70%);
    background-color: $primaryColor;
}

.switch__input:checked:before {
    // background-color: hsl(50,90%,50%);
    background-color: $primaryColor;
}

.switch__input:checked:after {
    background-color: hsl(0, 0%, 100%);

    transform: translateX(1.5em);
}

.switch__input:checked~.switch__icon--light,
.switch__input:checked~.switch__icon--light polyline {
    transition-delay: 0s;
    transition-timing-function: var(--trans-timing-in);
}

.switch__input:checked~.switch__icon--light {
    opacity: 0;
    transform: translateX(-0.75em) rotate(-30deg) scale(0.75);
}

.switch__input:checked~.switch__icon--light polyline {
    stroke-dashoffset: 1.5;
}

.switch__input:checked~.switch__icon--dark {
    opacity: 1;
    transform: translateX(-1.5em);
    transition-delay: calc(0.3s / 2);
    transition-timing-function: var(--trans-timing-out);
}

.switch__sr {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

// toggle switch

// Tooltip
.theme-tooltip {
    .tooltip-inner {
        background: $themeToolTip !important;
        color: $whiteColor !important;
    }
}

// Tooltip

.Qr-modal {
    top: 10% !important;

    .close-popup {
        font-size: 28px !important;
    }

    .qr-image {
        padding: 5px 20px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: .875rem !important;
            line-height: 1.25rem;
        }

        img {
            border: 4px solid $primaryColor;
            width: 60%;
        }
    }

    .top-image {
        padding: 5px 20px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;

        p {
            font-size: .875rem !important;
            line-height: 1.25rem;
        }

        img {
            // transform: rotate(-10deg);
            width: 40%;
        }
    }

    .top-btns {
        padding: 5px 20px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .qr-share {
        padding: 5px 20px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            border: 1px solid $primaryColor;
            border-radius: 4px;
            padding: 3px;
            background-color: $primaryColor;
            width: 10%;
        }
    }

    .store-imgs {
        display: flex;
        padding: 20px 40px;
        align-items: center;
        justify-content: space-between;
    }

    .modal-content {
        box-shadow: $borderColor 0px 0px 5px 1px !important;
    }
}



.credit-list-modal {
    top: 10% !important;

    .credit-box-div {
        padding: 10px;
        margin-bottom: 40px;
        .credit-col-box {
            padding: 10px;
            box-shadow: $card_box_shadow;
            &:hover {
                box-shadow: $card_shadow_hover;
            }
        }
    }

    .modal-dialog{
        max-width: 80%;
    }

    .close-popup {
        font-size: 28px !important;
    }

    .qr-image {
        padding: 5px 20px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: .875rem !important;
            line-height: 1.25rem;
        }

        img {
            border: 4px solid $primaryColor;
            width: 60%;
        }
    }

    .top-image {
        padding: 5px 20px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;

        p {
            font-size: .875rem !important;
            line-height: 1.25rem;
        }

        img {
            // transform: rotate(-10deg);
            width: 40%;
        }
    }

    .top-btns {
        padding: 5px 20px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .qr-share {
        padding: 5px 20px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            border: 1px solid $primaryColor;
            border-radius: 4px;
            padding: 3px;
            background-color: $primaryColor;
            width: 10%;
        }
    }

    .store-imgs {
        display: flex;
        padding: 20px 40px;
        align-items: center;
        justify-content: space-between;
    }

    .modal-content {
        box-shadow: $borderColor 0px 0px 5px 1px !important;
    }
}