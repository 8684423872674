@import "variables";
@import "mixins";

.add-user-details-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    // align-items: center;
    // border: 2px solid #e0e5eb;
    border-radius: 10px;
    // margin: 12% auto;
    margin: auto;
    height: 100vh;
}

.add-user-details-menu-numbers {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    cursor: pointer;
}

.add-user-details-menu-number-one {
    width: 40px;
    height: 40px;
    border: 1px solid #e0e5eb;
    border-radius: 50%;
    margin-right: 15px;
    color: #fff;
}

.add-user-details-menu-number-one span {
    position: absolute;
    top: 31px;
    left: 32px;
}

.add-user-details-menu-name {
    color: $primaryText_Color;
    font-size: 14px;
    font-weight: 400;
}

.add-user-details-first-div {
    display: flex;
    flex-direction: column;
    width: 15%;
    // padding: 20px 0px;
}

.add-user-details-second-div {
    width: 85%;
    border-left: 2px solid #e0e5eb;
    overflow: auto;
}

.add-user-details-second-div-header {
    padding: 32px 30px 32px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $whiteColor;
    // overflow: auto;
}

.amc-single-image {
    padding: 0px 30px;
    height: 100vh;
    overflow: auto;
}

.add-user-details-next-icon {
    border: 1px solid;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 80px;
    bottom: 10px;
}

.amc-arrow-button {
    border: 1px solid;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    left: 600px;
    // bottom: 10px;

    i {
        position: absolute;
        top: 9px;
        left: 13px;
        font-size: 20px;
    }

}

.leave-page-next-btn {
    top: 410px;
}

.add-user-details-next-icon i {
    position: absolute;
    top: 9px;
    left: 13px;
    font-size: 20px;
}

.add-user-details-menu-numbers.active .add-user-details-menu-number-one {
    background-color: $compStatusColor;
}

.add-user-details-menu-numbers.inactive .add-user-details-menu-number-one {
    background-color: $secondaryColor;
}

.upload-attachments-div label {
    border: none;
    padding: 7px;
    background-color: $secondaryColor;
    color: $whiteColor;
    border-radius: 8px;
}

.upload-attachments-div i {
    padding: 3px;
}

.amc-second-container-header {
    padding-bottom: 10px;
}

.amc-second-container {
    display: flex;

}

.amc-second-container-labels {
    padding-bottom: 25px;

    span {
        display: grid;
        grid-template-columns: 150px 20px 250px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1900px) {
    .amc-arrow-button {
        left: 400px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1900px) {
    .amc-second-container {
        display: block;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1900px) {
    .add-user-details-first-div {
        width: 20%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1900px) {
    .add-user-details-second-div {
        width: 85%;
    }
}

.machines-next-arrow-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;

    span {
        font-size: 40px;
        cursor: pointer;
    }
}

.salesbee-logo-machines-add-page {
    // height: 100%;
    width: 100%;
    padding: 0px 30px;
}

.machine-details-cards {
    .card {
        border: none;
    }

    .accordion-button:focus {
        box-shadow: none;
    }
}

.machines-logo-div {
    background-color: #fff;
    width: 100%;
    height: 50px;
}

.add-page-save-cancel {
    padding: 15px 10px 10px 15px;
    // border-top: 1px solid $borderColor;
    display: flex;
    justify-content: flex-end;
    border-radius: 0px 0px 6px 6px;
}

.btn-add-machine-img {
    width: 100%;
    text-align: center;
}

.amc-add-icon {
    .amc-plus-icon {
        width: 25px;
        height: 25px;
        text-align: center;
        vertical-align: middle;
        margin: 10px 5px;
        padding: 4px 8px;
        border-radius: 5px;
        color: #fff;
        background-color: $primaryColor;
    }
}